<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'fasney',
    titleTemplate: '%s - 無卡做自己',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'fasney 隨時金援你，無卡做自己。三大服務，後支付: 無卡享樂，無卡零利率，買完下個月再付。好好貸: 即刻金援，手機貸2萬，機車貸6萬。慢慢還: 靈活繳款，帳單來了，一年內任你分期付。信用在於人的身上而不在銀行裡，打破銀行制度的無卡宣言。'
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: 'fasney, 無信用卡, 無卡, 無卡享樂, 後支付, 好好貸, 慢慢還'
      }
    ]
  }
}
</script>
<style lang="scss">
#app {
  font-family: 'Noto Sans TC', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  width: 100%;
  height: 100%;
}
</style>
