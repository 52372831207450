import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/scss/main.scss'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueMeta from 'vue-meta'
import axios from 'axios'
Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.prototype.$http = axios
new Vue({
  router,
  store,
  created() {
    AOS.init()
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
  render: h => h(App)
}).$mount('#app')
