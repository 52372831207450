<template>
  <div>
    <header>
      <Navbar />
    </header>
    <main id="home">
      <Jumbotron />
      <Products />
      <CompanyInfo />
      <Question />
    </main>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar'
import Jumbotron from '@/components/home/Jumbotron'
import Products from '@/components/home/Products'
import CompanyInfo from '@/components/home/CompanyInfo'
import Question from '@/components/home/Question'
import Footer from '@/components/common/Footer'

export default {
  name: 'Home',
  components: {
    Navbar,
    Jumbotron,
    Products,
    CompanyInfo,
    Question,
    Footer
  }
}
</script>
