<template>
  <nav class="navbar">
    <div class="container navbar-container">
      <div class="navbar-img-container">
        <h1 class="navbar-logo">
          <router-link to="/">
            <img
              src="https://static.fasney.me/sff/fasney/image/home/fasney-logo.svg"
              alt="fasney無卡做自己，隨時金援你"
            >
            <span>fasney無卡做自己，隨時金援你</span>
          </router-link>
        </h1>
        <svg
          v-if="hiddenMobileMenu"
          class="bi bi-list d-xl-none mr-2"
          width="1.5em"
          height="1.5em"
          viewBox="0 0 16 16"
          fill="#000"
          xmlns="http://www.w3.org/2000/svg"
          @click="hiddenMobileMenu = false"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
        <svg
          v-if="!hiddenMobileMenu"
          width="1.5em"
          height="1.5em"
          viewBox="0 0 16 16"
          class="bi bi-x d-xl-none mr-2"
          fill="#000"
          xmlns="http://www.w3.org/2000/svg"
          @click="hiddenMobileMenu = true"
        >
          <path
            fill-rule="evenodd"
            d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
          />
          <path
            fill-rule="evenodd"
            d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
          />
        </svg>
      </div>
      <ul class="d-none d-xl-flex nav">
        <li
          @mouseover="changeText('newsEnText', '最新消息')"
          @mouseleave="changeText('newsEnText', 'NEWS')"
        >
          <router-link
            :class="{ 'text-hover-blue': newsEnText === '最新消息' }"
            to="/news/all.html"
            class="nav-link"
          >
            {{ newsEnText }}
          </router-link>
        </li>
        <li
          @mouseover="changeText('productEnText', '產品介紹')"
          @mouseleave="changeText('productEnText', 'PRODUCTS')"
        >
          <router-link
            :class="{ 'text-hover-blue': productEnText === '產品介紹' }"
            to="/products/fapoints.html"
            class="nav-link"
          >
            {{ productEnText }}
          </router-link>
          <div
            :class="{ 'triangle-up': productEnText === '產品介紹' }"
            class="triangle"
          />
          <ul>
            <li class="border-top-blue">
              <router-link to="/products/fapoints.html">
                fa points
              </router-link>
            </li>
            <li>
              <router-link to="/products/postpay.html">
                後支付
              </router-link>
            </li>
            <li>
              <router-link to="/products/loan.html">
                好好貸
              </router-link>
            </li>
            <li>
              <router-link to="/products/instalment.html">
                慢慢還
              </router-link>
            </li>
          </ul>
        </li>
        <li
          @mouseover="changeText('supportEnText', '財富指南')"
          @mouseleave="changeText('supportEnText', 'SUPPORT')"
        >
          <router-link
            :class="{ 'text-hover-blue': supportEnText === '財富指南' }"
            to="/support/all"
            class="nav-link"
          >
            {{ supportEnText }}
          </router-link>
        </li>
        <li
          @mouseover="changeText('companyEnText', '公司介紹')"
          @mouseleave="changeText('companyEnText', 'COMPANY')"
        >
          <router-link
            :class="{ 'text-hover-blue': companyEnText === '公司介紹' }"
            to="/company/about.html"
            class="nav-link"
          >
            {{ companyEnText }}
          </router-link>
          <div
            :class="{ 'triangle-up': companyEnText === '公司介紹' }"
            class="triangle"
          />
          <ul>
            <li class="border-top-blue">
              <router-link to="/company/about.html">
                關於fasney
              </router-link>
            </li>
          </ul>
        </li>
        <!-- <li class="fa-list">
          <a
            href="https://5000.fasney.com"
            class="nav-link event-fa-text"
            target="_blank"
            rel="noopener noreferrer"
          >
            fasney x 振興fa券
            <img
              src="https://static.fasney.me/sff/fasney/image/home/fasney-fa-icon.png"
              alt="fasney無卡做自己，隨時金援你"
            >
          </a>
        </li> -->
      </ul>
      <router-link
        class="d-none d-xl-block btn btn-app"
        to="/download"
      >
        GET THE APP
      </router-link>
    </div>
    <ul
      :class="{ showMobileMenu: !hiddenMobileMenu }"
      class="d-xl-none mobile-menu"
    >
      <li>
        <router-link
          to="/news/all.html"
          class="mobile-nav-link"
          @click.native="hiddenMobileMenu = true"
        >
          最新消息
        </router-link>
      </li>
      <li
        class="position-relative"
        :class="{ 'bg-sub-menu': !hiddenProductSub }"
      >
        <a
          class="mobile-nav-link border-bottom border-white"
          @click="hiddenProductSub = !hiddenProductSub"
        >產品介紹</a>
        <div
          :class="{ 'triangle-up': !hiddenProductSub }"
          class="triangle"
          @click="hiddenProductSub = !hiddenProductSub"
        />

        <ul
          :class="{ 'product-sub-hidden': hiddenProductSub }"
          class="product-mobile-menu"
        >
          <li class="border-bottom border-white">
            <router-link
              to="/products/fapoints.html"
              class="nav-link"
              @click.native="hiddenMobileMenu = true"
            >
              fa points
            </router-link>
          </li>
          <li class="border-bottom border-white">
            <router-link
              to="/products/postpay.html"
              class="nav-link"
              @click.native="hiddenMobileMenu = true"
            >
              後支付
            </router-link>
          </li>
          <li class="border-bottom border-white">
            <router-link
              to="/products/loan.html"
              class="nav-link"
              @click.native="hiddenMobileMenu = true"
            >
              好好貸
            </router-link>
          </li>
          <li>
            <router-link
              to="/products/instalment.html"
              class="nav-link"
              @click.native="hiddenMobileMenu = true"
            >
              慢慢還
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link
          to="/support/all"
          class="mobile-nav-link"
        >
          財富指南
        </router-link>
      </li>
      <li :class="{ 'bg-sub-menu': !hiddenCompanySub }">
        <a
          class="mobile-nav-link border-bottom border-white"
          @click="hiddenCompanySub = !hiddenCompanySub"
        >公司介紹</a>
        <div
          :class="{ 'triangle-up': !hiddenCompanySub }"
          class="triangle"
          @click="hiddenCompanySub = !hiddenCompanySub"
        />
        <ul
          :class="{ 'product-sub-hidden': hiddenCompanySub }"
          class="product-mobile-menu"
        >
          <li class="border-bottom border-white">
            <router-link
              to="/company/about.html"
              class="nav-link"
              @click.native="hiddenMobileMenu = true"
            >
              關於Fasney
            </router-link>
          </li>
          <!-- <li class="border-bottom border-white">
            <router-link
              to="/company/contact"
              class="nav-link"
              @click.native="hiddenMobileMenu = true"
            >
              聯絡我們
            </router-link>
          </li> -->
        </ul>
      </li>
      <!-- <li>
        <a
          href="https://5000.fasney.com"
          class="mobile-nav-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          fasney x 振興fa券
          <img
            src="https://static.fasney.me/sff/fasney/image/home/fasney-fa-icon.png"
            alt="fasney無卡做自己，隨時金援你"
          >
        </a>
      </li> -->
      <!-- <li>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScVQlZ_ZIG3a9LXt_RI1TtXdyLwRvFG4QuC142_BTPXNTXr4Q/viewform"
          class="mobile-nav-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          加入合作店家
        </a>
      </li> -->
      <li>
        <a
          class="col-6 col-md-4"
          href="https://apps.apple.com/tw/app/id1519571690"
        >
          <img
            class="img-fluid"
            src="https://static.fasney.me/sff/fasney/image/footer/apple_store_mobile.png"
            alt="fasney Apple Store"
          >
        </a>
        <a
          class="col-6 col-md-4"
          href="https://play.google.com/store/apps/details?id=com.urfintech.fasney&hl=zh_TW"
        >
          <img
            class="img-fluid"
            src="https://static.fasney.me/sff/fasney/image/footer/google_play_mobile.png"
            alt="fasney Google Play"
          >
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      hiddenMobileMenu: true,
      hiddenProductSub: false,
      hiddenCompanySub: true,
      newsEnText: 'NEWS',
      productEnText: 'PRODUCTS',
      supportEnText: 'SUPPORT',
      companyEnText: 'COMPANY',
      cooperativeEnText: 'COOPERATIVE'
    }
  },
  methods: {
    changeText(moveEnText, changeText) {
      this[moveEnText] = changeText
    }
  }
}
</script>
