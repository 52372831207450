<template>
  <footer>
    <div class="footer-image-container d-xl-none">
      <a href="https://apps.apple.com/tw/app/id1519571690">
        <img
          class="footer-image"
          src="https://static.fasney.me/sff/fasney/image/footer/app-store.png"
          alt="fasney App Store"
        >
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.urfintech.fasney&hl=zh_TW"
      >
        <img
          class="footer-image"
          src="https://static.fasney.me/sff/fasney/image/footer/google-play.png"
          alt="fasney Google Play"
        >
      </a>
    </div>
    <div class="container footer-container clearfix">
      <div class="d-none d-xl-block float-left text-left pt-3">
        <p
          style="font-family: 'Ubuntu', sans-serif;"
          class="font-weight-bold"
        >
          DOWNLOAD NOW
        </p>
        <router-link
          to="/download"
          class="btn btn-download"
        >
          <!-- <button type="button"  /> -->
        </router-link>
      </div>
      <ul class="footer-list">
        <li>
          LEGAL
          <ul>
            <li>
              <router-link
                class="text-white"
                to="/services"
              >
                服務條款
              </router-link>
            </li>
            <li>
              <router-link
                class="text-white"
                to="/policy"
              >
                隱私權政策
              </router-link>
            </li>
          </ul>
        </li>

        <li>
          FAQS
          <ul>
            <li>
              <router-link
                class="text-white"
                :to="{ name: 'Question', params: { type: 'menberQuestion' } }"
              >
                申請問題
              </router-link>
            </li>
            <li>
              <router-link
                class="text-white"
                :to="{ name: 'Question', params: { type: 'pay' } }"
              >
                繳款問題
              </router-link>
            </li>
          </ul>
        </li>
        <li class="social-list">
          SOCIAL MEDIA
          <ul>
            <li>
              <a
                style="font-family: 'Ubuntu', sans-serif;"
                class="text-white"
                href="https://www.facebook.com/fasney/"
              >
                Facebook
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <img
        src="https://static.fasney.me/sff/fasney/image/footer/fasney-footer-logo.png"
        class="footer-logo-image d-xl-none"
        alt="fasney 無卡做自己，隨時金援你"
      >
    </div>
    <div class="copyright">
      <small>© 2020 美而快電商金融科技股份有限公司</small>
      <small
        class="d-block d-xl-inline pl-xl-1"
        style="font-family: 'Ubuntu', sans-serif;"
      >United Recommend FinTech Co., Ltd. All Right Reserved.</small>
      <address
        style="font-size:80%;font-weight:400"
        class="my-1"
      >
        台北市南港區南港路三段47巷8號1樓
      </address>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
