<template>
  <section class="container products">
    <h3 class="font-small-24 font-xl-36 border-gold">
      OUR PRODUCTS
    </h3>
    <p class="font-small-32 font-xl-48 text-letter-2">
      三大服務<strong>完整生活</strong>
    </p>
    <div class="mt-xl-5 product-card-group">
      <div class="col-xl-4 product-card">
        <div>
          <strong class="d-block mb-0 font-small-18 font-xl-24">
            無卡享樂
          </strong>
          <h4 class="font-small-36 text-letter-2 border-blue">
            後支付
          </h4>
          <div class="d-xl-none product-info">
            <p class="mb-0">
              無卡零利率
            </p>
            <p>買完下個月再付</p>
            <router-link
              class="btn btn-more rounded-pill"
              to="/products/postpay.html"
            >
              了解更多
            </router-link>
          </div>
        </div>
        <div>
          <picture>
            <source
              data-aos="fade-up"
              data-aos-duration="1000"
              class="product-img"
              media="(min-width: 1200px)"
              srcset="https://static.fasney.me/sff/fasney/image/home/fasney-postpay.png"
              alt="無卡享樂-後支付"
            >
            <img
              data-aos="fade-up"
              data-aos-duration="1000"
              class="product-img"
              src="https://static.fasney.me/sff/fasney/image/home/fasney-postpay-mobile.png"
              alt="無卡享樂-後支付"
            >
          </picture>
          <div class="d-none d-xl-block product-info">
            <p class="mt-3 mb-0 pt-4">
              無卡零利率
            </p>
            <p>買完下個月再付</p>
            <router-link
              class="btn btn-more rounded-pill font-xl-18"
              to="/products/postpay.html"
            >
              了解更多
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-4 order-3 order-xl-2 product-card">
        <div>
          <strong class="d-block mb-0 font-small-18 font-xl-24">
            即刻金援
          </strong>
          <h4 class="font-small-36 text-letter-2 border-blue">
            好好貸
          </h4>
          <div class="d-xl-none product-info">
            <p class="mb-0">
              日利率0.043%
            </p>
            <p>手機貸2萬 機車貸6萬</p>
            <router-link
              class="btn btn-more rounded-pill"
              to="/products/loan.html"
            >
              了解更多
            </router-link>
          </div>
        </div>
        <div>
          <picture>
            <source
              data-aos="fade-up"
              data-aos-duration="1000"
              class="product-img"
              media="(min-width: 1200px)"
              srcset="https://static.fasney.me/sff/fasney/image/home/fasney-loan.png"
              alt="即刻金援-好好貸"
            >
            <img
              data-aos="fade-up"
              data-aos-duration="1000"
              class="product-img"
              src="https://static.fasney.me/sff/fasney/image/home/fasney-loan-mobile.png"
              alt="即刻金援-好好貸"
            >
          </picture>
          <div class="d-none d-xl-block product-info">
            <p class="mt-3 mb-0 pt-4">
              日利率0.043%
            </p>
            <p>手機貸2萬 機車貸6萬</p>
            <router-link
              class="btn btn-more rounded-pill font-xl-18"
              to="/products/loan.html"
            >
              了解更多
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-4 order-2 order-xl-3 product-card">
        <div>
          <div class="d-none d-xl-block">
            <strong class="d-block mb-0 font-small-18 font-xl-24">
              靈活繳款
            </strong>
            <h4 class="font-small-36 border-blue text-letter-2">
              慢慢還
            </h4>
          </div>
          <img
            data-aos="fade-up"
            data-aos-duration="1000"
            class="product-img"
            src="https://static.fasney.me/sff/fasney/image/home/fasney-installment.png"
            alt="靈活繳款 慢慢還"
          >
        </div>
        <div>
          <div class="d-xl-none">
            <strong class="d-block mb-0 font-small-18">靈活繳款</strong>
            <p class="font-small-36 border-blue text-letter-2">
              慢慢還
            </p>
          </div>
          <div class="product-info">
            <p class="mb-0 mt-xl-3 pt-xl-4">
              帳單來了
            </p>
            <p>繳總額、繳最低任你選</p>
            <router-link
              class="btn btn-more rounded-pill font-xl-18"
              to="/products/instalment.html"
            >
              了解更多
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Products'
}
</script>
