import Vue from 'vue'
import Vuex from 'vuex'
import { UPDATE_MESSAGE, REMOVE_MESSAGE } from './mutationType'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    messages: []
  },
  mutations: {
    [UPDATE_MESSAGE](state, payload) {
      state.messages.push(payload)
    },
    [REMOVE_MESSAGE](state, index) {
      state.messages.splice(index, 1)
    }
  },
  actions: {
    /**
     * show the prompt message on top of the view
     * @param {object} payload
     * @param {string} payload.message error message
     * @param {string} payload.status popup status (warning/ success-change/ danger-fail)
     */
    updateMessage({ commit, dispatch }, payload) {
      const timestamp = Math.floor(new Date() / 1000)
      commit(UPDATE_MESSAGE, { ...payload, timestamp })
      dispatch('removeMessageWithTiming', timestamp)
    },
    /**
     * remove the prompt message on top of the view after four seconds
     * @param {number} timestamp time
     */
    removeMessageWithTiming({ state, commit }, timestamp) {
      setTimeout(() => {
        state.messages.forEach((item, index) => {
          if (item.timestamp === timestamp) {
            commit(REMOVE_MESSAGE, index)
          }
        })
      }, 5000)
    },
    /**
     * remove the prompt message on top of the view after user click close button
     * @param {number} index the index of the messages array
     */
    removeMessage({ commit }, index) {
      commit(REMOVE_MESSAGE, index)
    }
  },
  modules: {}
})
