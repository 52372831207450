import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/news',
    name: 'News       ',
    component: () => import('@/views/News.vue'),
    children: [
      {
        path: 'all.html',
        name: 'AllNews',
        component: () =>
          import(/* webpackChunkName: "news" */ '@/components/news/AllNews.vue')
      },
      {
        path: 'postpay-news.html',
        name: 'PostpayNews',
        component: () =>
          import(
            /* webpackChunkName: "news" */ '@/components/news/postpay/PostpayNews.vue'
          )
      },
      {
        path: 'market-news.html',
        name: 'MarketNews',
        component: () =>
          import(
            /* webpackChunkName: "news" */ '@/components/news/market/MarketNews.vue'
          )
      },
      {
        path: 'news-update.html',
        name: 'NewsUpdate',
        component: () =>
          import(
            /* webpackChunkName: "news" */ '@/components/news/update/UpdateNews.vue'
          )
      },
      {
        path: 'news-update-bill.html',
        name: 'NewsUpdate2',
        component: () =>
          import(
            /* webpackChunkName: "news" */ '@/components/news/newUpdate/UpdateNews.vue'
          )
      },
      {
        path: 'normal-event.html',
        name: 'NewsEvent',
        component: () =>
          import(
            /* webpackChunkName: "news" */ '@/components/news/normalEvent/Event.vue'
          )
      },
      {
        path: 'december-event.html',
        name: 'NewsEvent2',
        component: () =>
          import(
            /* webpackChunkName: "news" */ '@/components/news/decEvent/Event.vue'
          )
      }
    ]
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('@/views/Products.vue'),
    children: [
      {
        path: 'postpay.html',
        name: 'Postpay',
        component: () =>
          import(
            /* webpackChunkName: "products" */ '@/components/products/postpay/Postpay.vue'
          )
      },
      {
        path: 'loan.html',
        name: 'Loan',
        component: () =>
          import(
            /* webpackChunkName: "products" */ '@/components/products/loan/Loan.vue'
          )
      },
      {
        path: 'instalment.html',
        name: 'Instalment',
        component: () =>
          import(
            /* webpackChunkName: "products" */ '@/components/products/instalment/Instalment.vue'
          )
      },
      {
        path: 'fapoints.html',
        name: 'FaPoints',
        component: () =>
          import(
            /* webpackChunkName: "products" */ '@/components/products/fapoints/FaPoints.vue'
          )
      }
    ]
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('@/views/Support.vue'),
    children: [
      {
        path: 'all',
        name: 'AllSupport',
        component: () =>
          import(
            /* webpackChunkName: "support" */ '@/components/support/AllSupport.vue'
          )
      },
      {
        path: 'post/postpay.html',
        name: 'PostpayPost',
        component: () =>
          import(
            /* webpackChunkName: "support" */ '@/components/support/postpay/Post.vue'
          )
      },
      {
        path: 'post/instalment.html',
        name: 'InstalmentPost',
        component: () =>
          import(
            /* webpackChunkName: "support" */ '@/components/support/instalment/Post.vue'
          )
      },
      {
        path: 'post/review.html',
        name: 'ReviewPost',
        component: () =>
          import(
            /* webpackChunkName: "support" */ '@/components/support/review/Post.vue'
          )
      },
      {
        path: 'post/suit.html',
        name: 'SuitPost',
        component: () =>
          import(
            /* webpackChunkName: "support" */ '@/components/support/suit/Post.vue'
          )
      }
    ]
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('@/views/Company.vue'),
    children: [
      {
        path: 'about.html',
        name: 'About',
        component: () =>
          import(
            /* webpackChunkName: "company" */ '@/components/company/About.vue'
          )
      },
      {
        path: 'media',
        name: 'Media',
        component: () =>
          import(
            /* webpackChunkName: "company" */ '@/components/company/Media.vue'
          )
      }
      // {
      //   path: 'contact',
      //   name: 'Contact',
      //   component: () => import('@/components/company/Contact.vue')
      // }
    ]
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('@/views/Download.vue')
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/views/Services.vue')
  },
  {
    path: '/policy',
    name: 'Policy',
    component: () => import('@/views/Policy.vue')
  },
  {
    path: '/question/:type',
    name: 'Question',
    component: () => import('@/views/Question.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (from.name !== 'Question' && to.name === 'Question') {
      return { x: 0, y: 0 }
    } else if (to.name === 'Question') {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
